


















































































































import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import LoginProvider from '../../providers/login.provider';
import Toaster from '../../mixins/toaster.mixin';
import { Captcha } from '../../types/general.type';

@Component
export default class LoginForm extends mixins(Toaster) {
  isCaptchaRequired = false;

  captcha: Captcha = {};

  captchaRules = [
    (v: string) => !!v || this.$t('requiredField'),
    (v: string) => (v && v.length === 5) || `${this.$t('minLetters')} 5`,
  ];

  valid = true;

  name = '';

  errors: object[] = [];

  nameRules = [
    (v: string) => !!v || this.$t('requiredField'),
    (v: string) => (v && v.length <= 50) || `${this.$t('maxLetters')} 50`,
    (v: string) => /^[A-Za-z0-9_. ]*$/.test(v)
      || /.+@.+\..+/.test(v)
      || this.$t('invalidInputData'),
  ];

  password = '';

  passwordRules = [
    (v: string) => !!v || this.$t('requiredField'),
    (v: string) => (v && v.length <= 50) || `${this.$t('maxLetters')} 50`,
  ];

  $refs!: {
    form: HTMLFormElement;
  };

  loginProvider = new LoginProvider();

  isValidForm() {
    return this.$refs.form.validate();
  }

  async submitLoginForm() {
    this.errors = [];
    this.$refs.form.validate();

    if (this.$refs.form.validate()) {
      try {
        const credentials = await this.loginProvider.userLogin({
          username: this.name,
          password: this.password,
          encodedText: this.captcha.encodedText,
          captchaText: this.captcha.text,
        });
        this.isCaptchaRequired = !!credentials.captcha && !!credentials.captcha.required;
        if (this.isCaptchaRequired) {
          this.captcha.data = credentials && credentials.captcha ? credentials.captcha.data : '';
          this.captcha.encodedText = credentials && credentials.captcha ? credentials.captcha.text : '';
          this.captcha.text = '';
          if (credentials.message) this.errors.push(credentials.message);
        } else if (credentials.token) {
          this.$store.commit('setUserAuth', {
            token: credentials.token,
            username: credentials.username,
            resetPassword: credentials.resetPassword,
            resetMessage: credentials.resetMessage,
            sessionDuration: credentials.sessionDuration,
          });
          const redirect = this.$route.query.redirect ? `${this.$route.query.redirect}` : '/';
          this.$router.push(redirect);
          this.successToaster({
            ar: 'تم تسجيل الدخول بنجاح',
            en: 'You have been logged in successfully',
          });
        }
      } catch (err) {
        this.captcha = {};
        this.isCaptchaRequired = false;
        this.errors.push(err);
      }
    }
  }

  @Watch('$store.state.lang.lang', { deep: true })
  translateValidations() {
    const el: NodeListOf<Element> = this.$el.querySelectorAll('.error--text');
    if (el.length) {
      this.$refs.form.validate();
    }
  }

  async reloadCaptcha() {
    this.errors = [];
    try {
      const data = await this.loginProvider.getCaptcha();
      if (data.captcha && data.captcha.required) {
        this.captcha.data = data.captcha.data;
        this.captcha.encodedText = data.captcha.text;
        this.captcha.text = '';
        this.isCaptchaRequired = true;
        this.$forceUpdate();
      }
    } catch (error) {
      this.errors.push(error);
    }
  }
}
