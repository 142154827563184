

































































import { Component, Vue } from 'vue-property-decorator';
import SequentialEntrance from 'vue-sequential-entrance';
import { Img } from '../../types/img.types';
import 'vue-sequential-entrance/vue-sequential-entrance.css';

Vue.use(SequentialEntrance);

@Component
export default class Users extends Vue {
  orgaiinizations: Img[] = [];

  imgs: Img[] = [
    {
      src: 'MET',
      href: 'http://metmans.edu.eg/index',
      alt: 'MET',
    },
    {
      src: 'mans',
      href: 'https://www.mans.edu.eg/',
      alt: 'mans',
    },
    {
      src: 'thebe',
      href: 'http://www.thebesacademy.org/',
      alt: 'thebe',
    },
    {
      src: 'HUE',
      href: 'https://new.horus.edu.eg/',
      alt: 'HUE',
    },
    {
      src: 'Damietta',
      href: 'http://www.du.edu.eg/',
      alt: 'Damietta',
    },
    {
      src: 'zag',
      href: 'http://www.zu.edu.eg/',
      alt: 'zag',
    },
  ];

  get imgWidth() {
    switch (this.$vuetify.breakpoint.name) {
      default:
        return 30;

      case 'xs':
        return 50;
      case 'sm':
        return 80;
      case 'md':
        return 100;
      case 'lg':
        return 150;
      case 'xl':
        return 150;
    }
  }

  created() {
    window.addEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const element: HTMLElement | null = this.$el.querySelector('#sequential');
    const elmentTop = element ? element.getBoundingClientRect().top : 0;
    if (elmentTop < window.innerHeight && !this.$vuetify.breakpoint.xs) {
      this.orgaiinizations = this.imgs;
    }
  }

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  mounted() {
    if (this.$vuetify.breakpoint.xs) {
      this.orgaiinizations = this.imgs;
    }
  }
}
