








































































































































































import Component, { mixins } from 'vue-class-component';
import Language from '../../mixins/language.mixin';

@Component
export default class Header extends mixins(Language) {
  slideToSection(section) {
    this.$emit('slideTo', section);
  }

  changeLang() {
    this.switchLang();
    if (this.$el.querySelector('.error--text')) {
      // this.$refs.form.validate();
    }
  }
}
