








import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import gsap from 'gsap';

@Component
export default class Counter extends Vue {
  tweenedNumber = 0;

  @Prop(Boolean) readonly animate!: boolean;

  @Prop(String) readonly number!: string;

  @Watch('animate', { deep: true })
  onPropertyChanged(value: string) {
    if (value) {
      this.countNumber(this.number);
    }
  }

  get animatedNumber() {
    return this.tweenedNumber.toFixed(0);
  }

  countNumber(value: string) {
    gsap.to(this.$data, { duration: 2, tweenedNumber: value });
  }
}
