
















import { Component, Vue, Prop } from 'vue-property-decorator';
import RichTextComponent from '../richText.component.vue';

@Component({
  components: { RichTextComponent },
})
export default class ArticleComponent extends Vue {
  validRichText = true;

  error = '';

  @Prop(Number) readonly index!: number;

  validatedRichText({
    content, valid, error, richTextIndex,
  }) {
    this.validRichText = !!valid;
    this.error = valid ? '' : error;

    this.$emit('answerVlidation', {
      content: [
        {
          text: content,
          sort: 0,
          correct: 1,
        },
      ],
      valid,
      index: richTextIndex,
      error: this.error,
    });
  }
}
