


















































































































































































import { Component, Vue } from 'vue-property-decorator';
import Counter from './Counter.component.vue';

@Component({
  components: { Counter },
})
export default class Process extends Vue {
  animate = false;

  created() {
    window.addEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const element: HTMLElement | null = this.$el.querySelector('#counters');
    if (element) {
      const elmentTop = element.getBoundingClientRect().top + 100;
      const elmentBottom = element.getBoundingClientRect().bottom + 100;
      if (elmentTop < window.innerHeight && elmentBottom > 0) {
        this.animate = true;
      } else {
        this.animate = false;
      }
    }
  }

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
