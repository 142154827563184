




























import { Component, Vue } from 'vue-property-decorator';
import FooterComponent from '../components/main/footer.component.vue';

import About from '../components/login/about.component.vue';
import Cover from '../components/login/cover.component.vue';
import LoginForm from '../components/login/loginForm.component.vue';
import Header from '../components/login/header.component.vue';
import Process from '../components/login/process.component.vue';
import Services from '../components/login/services.component.vue';
import Policy from '../components/login/policy.component.vue';
import Users from '../components/login/Users.component.vue';

@Component({
  components: {
    About,
    Cover,
    LoginForm,
    Header,
    Process,
    Services,
    Policy,
    Users,
    FooterComponent,
  },
})
export default class Login extends Vue {
  /* eslint-disable class-methods-use-this */
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.state.auth.token) {
        vm.$router.push('/');
      }
    });
  }

  slideToSection(section) {
    const element: HTMLElement | null = this.$el.querySelector(`#${section}`);
    if (element) {
      this.$vuetify.goTo(element, {
        offset: 145,
      });
    }
  }
}
