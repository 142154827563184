










































































































































































































































































































































































































































































































































import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import CoursesProvider from '@/providers/courses.provider';
import DialogComponent from '@/components/dialog.component.vue';
import SystemCourseAddEditDialog from '@/components/courses/systemCourseAddEdit.dialog.vue';
import CourseActivationDialog from '@/components/courses/coursesActivation.dialog.vue';
import CourseElectronicExams from '@/components/courses/CourseElectronicExams.component.vue';
import SelectedCourse from '@/components/SelectedCourse.component.vue';
import {
  TreeView,
  ChartStatus,
  CourseDataResponse,
  IlosLangResponse,
  BasicResponse,
} from '@/types/course.type';
import {
  APPIDS, COURSE_TYPE, Rights, COURSES_ILOS_ROUTE_NAME, COURSES_ROUTE_NAME,
} from '@/enums/';
import PieChartComponent from '@/shared/pieChart.component.vue';
import { GeneralResponse, Lang } from '../../types/general.type';
import Toaster from '../../mixins/toaster.mixin';

@Component({
  components: {
    PieChartComponent,
    SelectedCourse,
    SystemCourseAddEditDialog,
    DialogComponent,
    CourseActivationDialog,
    CourseElectronicExams,
  },
})
export default class CoursesView extends mixins(Toaster) {
  appId = APPIDS.COURSES;

  tabsItems: string[] = [
    'titles.COURSE_STATISTICS',
    'titles.ELECTRONIC_EXAMS',
  ];

  tab = '';

  resetILosDialog1 = false;

  resetILosDialog2 = false;

  showSystemEditDialog = false;

  deleteCourseDialog = false;

  response!: CourseDataResponse;

  ilosView!: TreeView[];

  id = 0;

  displayConfirmationDialog = false;

  subjectsToBeActivated = false;

  courseActive = false;

  generatedExams!: ChartStatus[];

  loadingIlosLang = false;

  loadingImport = false;

  showIlosLangDialog = false;

  availableLang: string[] = [];

  ilosLangInfoMessage: Lang = { ar: '', en: '' };

  chosenLang = '';

  $options!: {
    filters: {
      servertranslate;
    };
  };

  servertranslate = this.$options.filters.servertranslate;

  loading = true;

  error = '';

  @Watch('lang', { deep: true })
  onPropertyChanged() {
    this.ilosView = this.ilosView.map((c) => ({
      ...c,
      name: this.servertranslate(
        this.response.ilosData.find((t) => t.iloId === c.headerId)?.iloName
          ?? '',
      ),
    }));
  }

  get courseId() {
    return this.$route ? this.$route.params.courseId : '';
  }

  get lang() {
    return this.$store.state.lang.lang;
  }

  canEdit() {
    return this.response.rights.includes(Rights.edit) && this.isExternalCourse;
  }

  canDelete() {
    return this.response.rights.includes(Rights.delete) && this.isExternalCourse;
  }

  canActivate() {
    return this.response.rights.includes(Rights.edit) && this.subjectsToBeActivated;
  }

  getCanEdit() {
    return this.response.rights.includes(Rights.edit);
  }

  onBack() {
    const { courseFilters } = this.$store.state.filters;
    this.$router.push({ name: COURSES_ROUTE_NAME.LIST, query: courseFilters });
  }

  get isDeleteDisabled() {
    const { canBeDeleted, hasDeletedExams } = this.response;
    return hasDeletedExams || !canBeDeleted;
  }

  get canImportIlos() {
    return (
      this.courseActive
      && this.response.rights.includes(Rights.edit)
      && !this.response.course.hasRelatedIlosInUse
      && !this.isExternalCourse
    );
  }

  get canresetIlos() {
    return (
      this.courseActive
      && this.response.rights.includes(Rights.edit)
      && this.response.course.hasRelatedIlosInUse
      && !this.isExternalCourse
    );
  }

  get isExternalCourse() {
    return (this.response.course.courseType === COURSE_TYPE.EXTERNAL_COURSE
        || this.response.course.courseType === COURSE_TYPE.EXTERNAL_COURSE_PGS);
  }

  get validImport() {
    return this.chosenLang || !this.availableLang.length;
  }

  get multiLangs() {
    return [
      { text: this.$t('labels.lang.en'), value: 'E' },
      { text: this.$t('labels.lang.ar'), value: 'A' },
    ];
  }

  async mounted() {
    this.$store.commit('selectCourse', { course: { courseId: this.courseId } });
    this.getCourse();
  }

  async getCourse() {
    try {
      this.loading = true;
      const response: CourseDataResponse = await CoursesProvider.getCourseView(
        this.appId,
        this.courseId,
      );
      this.response = { ...response };
      this.courseActive = Boolean(response.course.active);
      this.generatedExams = response.generatedExams.reduce(
        (acc: ChartStatus[], item) => {
          const index = acc.findIndex(
            (c: ChartStatus) => c.statusId === item.statusId,
          );
          if (index === -1) {
            acc.push({
              ...item,
              count: 1,
            });
          } else {
            acc[index].count += 1;
          }
          return acc;
        },
        [],
      );
      this.ilosView = response.course.flexibleIlos ? response.courseIlos : response.courseIlos.map((c) => ({
        ...c,
        name: this.servertranslate(c.name),
        children: c.children,
      }));
      this.loading = false;
    } catch (err) {
      this.errorToaster(err as Lang, { duration: 5000 });
      this.loading = false;
      this.error = err;
    }
  }

  async activate() {
    this.displayConfirmationDialog = false;
    this.subjectsToBeActivated = true;
  }

  async getIlosLang() {
    try {
      this.loadingIlosLang = true;
      const {
        langInfoMessage,
        availableLang,
      }: IlosLangResponse = await CoursesProvider.getCourseIlosLang(
        this.appId,
        this.courseId,
      );
      this.loadingIlosLang = false;
      this.showIlosLangDialog = true;
      this.ilosLangInfoMessage = langInfoMessage;
      this.availableLang = availableLang || [];
      if (this.availableLang.length === 1) {
        const [chosenLang] = this.availableLang;
        this.chosenLang = chosenLang;
      }
    } catch (err) {
      this.loadingIlosLang = false;
      this.errorToaster(err as Lang, { duration: 5000 });
    }
  }

  async importCourseIlos() {
    try {
      this.loadingImport = true;
      const importCourseIlosResponse: BasicResponse = await CoursesProvider.importCourseIlos(
        this.appId,
        this.courseId,
        { ilosLang: this.chosenLang },
      );
      this.loadingImport = false;
      this.showIlosLangDialog = false;
      this.successToaster(importCourseIlosResponse.message as Lang, { duration: 5000 });
      if (importCourseIlosResponse.redirect) {
        this.$router.push({
          name: COURSES_ILOS_ROUTE_NAME.COURSES_ILOS_VIEW,
          params: {
            courseId: this.courseId,
          },
        });
      } else {
        setTimeout(() => {
          this.$router.go(0);
        }, 1000);
      }
    } catch (err) {
      this.loadingImport = false;
      this.showIlosLangDialog = false;
      this.errorToaster(err as Lang, { duration: 5000 });
    }
  }

  indexingChildren(childrens) {
    return childrens.map((child, index) => {
      if (child.children && child.children.length) {
        return this.indexingChildren(child.children);
      }
      return {
        ...child,
        name: `${index + 1} - ${child.name}`,
      };
    });
  }

  closeDialog() {
    this.subjectsToBeActivated = false;
  }

  closeDeleteConfirmationDialog() {
    this.deleteCourseDialog = false;
  }

  cancel() {
    this.displayConfirmationDialog = false;
  }

  showResertIlosConfirm2() {
    this.resetILosDialog1 = false;
    this.resetILosDialog2 = true;
  }

  deletIlosDialogs() {
    this.resetILosDialog1 = true;
  }

  async deletIlos() {
    try {
      this.resetILosDialog1 = false;
      this.resetILosDialog2 = false;
      const result: Lang = await CoursesProvider.resetIlos(
        this.appId,
        this.courseId,
      );
      this.successToaster(result);
      this.$router.go(0);
    } catch (err) {
      this.errorToaster(err);
    }
  }

  async deleteCourse() {
    try {
      this.closeDeleteConfirmationDialog();
      const msg: GeneralResponse = await CoursesProvider.deleteCourse(
        this.appId,
        this.courseId,
      );
      this.successToaster(msg.message);
      this.onBack();
    } catch (err) {
      this.errorToaster(err as Lang);
    }
  }
}
