






































































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class About extends Vue {
  myCustomAnimationClassName = 'custom';

  created() {
    window.addEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const element: HTMLElement | null = this.$el.querySelector('#aboutWapper');
    if (element) {
      const elmentTop = element.getBoundingClientRect().top + 100;
      const elmentBottom = element.getBoundingClientRect().bottom + 100;
      if (elmentTop < window.innerHeight && elmentBottom > 0) {
        this.myCustomAnimationClassName = '';
      }
    }
  }

  mounted() {
    if (this.$vuetify.breakpoint.xs) {
      this.myCustomAnimationClassName = '';
    }
  }

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
