



























import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Cover extends Vue {
  carouselImages = [
    {
      src: 'assets/img/qbaner1.svg',
    },
    {
      src: 'assets/img/banner2.svg',
    },
  ];
}
